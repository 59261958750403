* {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
}

html,
body {
  width: 100%;
  height: 100%;

  cursor: default;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 0.8vw;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: 100%;
}

i {
  font-style: normal;
  line-height: 1;

  cursor: pointer;
}

.icon-cross:before {
  content: '×';

  display: block;

  padding: 0.1vw;
}
