.welcome {
  overflow: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 15vw;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1.2vw;
  font-weight: 400;
  line-height: 1.5;

  z-index: 1;
}

@media all and (max-height: 45vw) {
  .welcome {
    align-items: baseline;
  }
}

.welcome__wrapper {
  padding: 0 5vw 5vw;
}

.welcome__logo {
  display: block;

  height: 15vw;

  margin: 0 auto;

  box-shadow: 0 0 5vw #fff;
  border-radius: 1vw;
}

.welcome ul {
  margin: 0.1vw 0 0.1vw 0.5vw;

  list-style-position: inside;
}

.welcome p {
  margin: 1vw 0;
}

.app {
  counter-reset: link;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
}

.map {
  counter-reset: block;

  flex: 1 1 auto;

  position: relative;

  overflow: auto;
}

.app--not-started .map {
  overflow: hidden;
}

.map:after {
  content: '';

  position: absolute;
  top: 50vw;
  left: 0;

  width: 100%;
  height: 10px;
}

.map-label {
  position: absolute;
}

.map-bg {
  position: absolute;
  top: 0;
  left: 0;
}

.block {
  counter-increment: block;

  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0.2vw 0.5vw;

  background-color: #fff;
  border: 1px solid #000;

  text-align: center;
}

.block--can-drop {
  background-color: #eee;
}

.block--active {
  background-color: #ccc;
}

.block--empty:before {
  content: counter(block);

  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  opacity: 0.5;
}

.block--with-error {
  color: #f00;

  border-color: #f00
}

.block__reset-btn {
  position: absolute;
  top: 0;
  right: 0;
}

.link {
  counter-increment: link;

  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0.2vw 0.5vw;

  /*border: 1px dotted #ffe4e4;*/

  text-align: center;
}

.link-value {
  background-color: #fff;
}

.link--empty:before {
  content: counter(link);

  box-sizing: border-box;

  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -0.6vw;

  width: 1.3vw;
  height: 1.3vw;

  background-color: #fff;
  border: 1px solid #000;
  border-radius: 50%;

  transform: translateY(-100%);

  color: rgba(0, 0, 0, 0.5);
  text-align: center;
}

.link--can-drop.link--empty:before {
  background-color: #ccc;
}

.link--active.link--empty:before {
  background-color: #eee;
}

.link--with-error {
  color: #f00;
}

.link--with-error.link--empty:before {
  border-color: #f00;
}

.link__reset-btn {
  position: absolute;
  top: 0;
  right: 0;
}

.sidebar {
  flex: 0 0 auto;

  width: 15vw;
  height: 100%;

  box-sizing: border-box;
  padding-top: 9vw;

  overflow-y: auto;

  text-align: center;
}

.app--not-started .sidebar {
  overflow: hidden;
}

.check-btn__wrapper {
  position: absolute;
  top: 0;
  right: 0;

  width: 15vw;

  padding-top: 0.8vw;

  background-color: #fff;

  z-index: 1;
}

.timer {
  padding: 0.6vw 0;

  font-size: 4vw;
}

.check-btn {
  width: 100%;

  padding: 0.8vw 0;

  background-color: #2199dd;

  font-size: 1vw;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;

  cursor: pointer;
}

.check-btn:active {
  transform: translateY(1px) scale(0.99);
}

.check-btn:disabled {
  opacity: 0.3;

  cursor: not-allowed;
}

.form-name {
  position: absolute;
  top: 10vw;
  left: 0;
  right: 0;
}

.form-name__field {
  box-sizing: border-box;

  width: 100%;

  padding: 0.5vw 0.5vw;

  border: 1px solid #000;

  font-size: 1vw;
}

.section {
  margin-top: 1vw;

  font-size: 1.2vw;

  background-color: #ccc;
}

.menu {
  list-style: none;
}

.menu__item {
  margin-top: 0.2vw;
  padding: 0.2vw 0.5vw;

  border: 1px solid #000;
  background-color: #fff;
}

.line {
  position: absolute;

  background-color: #000;
}

.line--vertical {
  width: 1px;
}

.line--vertical.line--with-border {
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
}

.line--horizontal {
  height: 1px;
}

.line--horizontal.line--with-border {
  margin-top: -2px;

  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
}

.line--with-top-arrow:before {
  content: '';

  position: absolute;
  top: 0;
  left: 50%;

  width: 0;
  height: 0;

  margin-left: -0.3vw;

  border-left: 0.3vw solid transparent;
  border-right: 0.3vw solid transparent;
  border-bottom: 0.8vw solid black;
}

.line--with-bottom-arrow:before {
  content: '';

  position: absolute;
  bottom: 0;
  left: 50%;

  width: 0;
  height: 0;

  margin-left: -0.3vw;

  border-left: 0.3vw solid transparent;
  border-right: 0.3vw solid transparent;
  border-top: 0.8vw solid black;
}

.line--with-left-arrow:before {
  content: '';

  position: absolute;
  top: 50%;
  left: -1px;

  width: 0;
  height: 0;

  margin-top: -0.3vw;

  border-top: 0.3vw solid transparent;
  border-bottom: 0.3vw solid transparent;
  border-right: 0.8vw solid black;
}

.line--with-right-arrow:before {
  content: '';

  position: absolute;
  top: 50%;
  right: -1px;

  width: 0;
  height: 0;

  margin-top: -0.3vw;

  border-top: 0.3vw solid transparent;
  border-bottom: 0.3vw solid transparent;
  border-left: 0.8vw solid black;
}

.app--not-started .map,
.app--not-started .section,
.app--not-started .menu {
  opacity: 0.1;

  filter: blur(0.3vw);
}

.result-modal {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  padding: 1.2vw;

  border: 2px solid #000;
  border-radius: 0.6vw;
  background-color: #fff;
}

.result-modal__content {
  font-size: 1.6vw;
  font-weight: normal;
  text-align: center;
}

.result-modal__close-btn {
  font-size: 2.2vw;
  line-height: 1;
}

.result-modal__close-btn {
  width: 100%;

  margin-top: 1.8vw;
  padding: 0.8vw 0;

  background-color: #2199dd;

  font-size: 1vw;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;

  cursor: pointer;
}

.result-modal__close-btn:active {
  transform: translateY(1px) scale(0.99);
}

.result-modal__content h3 {
  margin-bottom: 0.6vw;

  font-size: 2.2vw;
}
